.div_consultararticulo {
  /*display: flex !important;  */
  flex-direction: column !important;
  flex-Wrap: wrap;
  align-items:center;
  justify-content: center;
  .div_infoarticulo{
    justify-content: center !important;
    display: flex; 
    /*flex-direction: column; */
    flex-Wrap: wrap;
    width: 100%;
    .div_titulo {
        justify-content: left !important;
        margin-bottom: 20px !important;
    }   
    .div_nombrearticulo {
      justify-content: left !important;
      align-items: left !important;
      min-width: 300px !important; 
      width: 900px !important;
      margin-bottom: 25px !important; 
    }
    .div_diseniounico{
      height: 900px !important;
      width: 90% !important;
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;
    }
    .div_boton_descargar{
      justify-content: flex-end !important;
      align-items: left !important;
      display: flex !important; //No toma este valor
      margin-top: 15px !important; 
      margin-bottom: 15px !important;
      width: 90%;
    }
  }
}
.div_datos{
  margin-left: 30px !important;
  margin-bottom: 15px !important;
}

.div_boton_modificar{
  margin-top: 25px !important; 
  display: flex;
  align-items:center;
  justify-content: center;
}

.principal-div {
  display: flex;
  align-items:center;
  justify-content: center;
  flex-wrap: wrap;
}
.div_especificaciones{
  margin-top: 25px !important; 
  display: flex;
  align-items:center;
  justify-content: center;
}

.descargar {
  height: 35px;
  width: 150px;
  color: #db2491;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  border: 1px solid #db2491;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 7px;
  
  }
.descargar:hover {
  height: 35px;
  width: 150px;
  color: #db2491;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  border: 1px solid #db2491;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 7px;
}
.descargar:active {
  height: 35px;
  width: 150px;
  color: #db2491;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  border: 1px solid #db2491;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 7px;
}