.div_mensaje {
    color: #333;
    font-size: 14px !important;
    font-family: "Roboto" !important;
    height: 36px !important;
    border-radius: 0px;
    &__success {
      border: 1px solid #389b73;
      background-color: #caf9e2;        
    }
    &__warning {
      border: 1px solid #ea8b18;
      background-color: #ffe9cc;
    }
    &__error{
      border: 1px solid #79134c;
      background-color: #ffeae6;
    }
  }