.div_consultaadj {
    display: flex;
    flex-direction: column;  
    font-size: 28px;
}

.div_botones {
    justify-content: center;
    align-items: center;
    display: flex;       
    min-width: 380px;   
    margin-top: 10px;   
}

.guardar, .guardar:focus {
    height: 35px;
    width: 160px;
    color: #fff;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #db2491;
  }

  .guardar:hover, .guardar:disabled, .guardar:disabled:hover {
    height: 35px;
    width: 160px;
    color: #fff;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #e149a4;
    border-radius: 5px;
    background-color: #e149a4;
}

.cancelar, .cancelar:focus {
    height: 35px;
    width: 160px;      
    color: #db2491;   
    padding-left: 0px;       
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #fff;
}

.cancelar:hover {
    height: 35px;
    width: 160px;      
    color: #db2491;   
    padding-left: 0px;       
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #fff;
}