.footer {
  background-color: #333333 !important;
  width: 100% !important;
  font-size: 12px !important;
  font-family: "Roboto", sans-serif !important;
  color: white !important;
  padding: 10px 20px 10px 20px !important;
}

.columna-footer{
  align-items: center !important;
  justify-content: center;
  align-items: center;
}

.links-ine{
  height: 100%;
  display: flex;
  align-items: center;
  color: white;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
}

.links-ine a {
  text-decoration: underline;
  color: white;
}

.instituto {
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.instituto svg {
  text-align: center;
}

.instituto span {
  text-align: right;
  width: 100%;
}

.area {
  text-align: center;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.revision {
  color: #333333;
  font-size: 9px;
}

.version {
  text-align: center;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.instituto a {
  text-decoration: underline;
  color: white;
}