.div_creararticulo {
    display: flex !important;  
    flex-direction: column !important;       
    .div_titulo {
        justify-content: left !important;
        margin-bottom: 20px !important;
    }   
    .div_nombrearticulo {
      justify-content: start !important;
      align-items: center !important;
      min-width: 300px !important; 
      width: 500px !important;
      margin-bottom: 25px !important; 
    }
    .div_diseniounico{
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;
      margin-bottom: 60px !important;        
    }
    .div_boton_agregar{
      display: flex !important; //No toma este valor
      justify-content: right !important; //No toma este valor
    }
    .div_especificaciones{
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      align-items: flex-start !important;
      margin-bottom: 60px !important;     
    }
    .div_especificaciones_col{   
      justify-content: space-around !important;
      width: 48% !important;     
    }
    .div_botones_agregar{
      display: flex !important;
      flex-direction: row !important;
      justify-content: flex-end !important;    
    }
    .div_botones_finales{ 
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-around !important;
      align-items: center !important;      
    }
    .encabezado {
        color: rgb(0, 0, 0);
        font-size: 24px !important;
        font-family: "Roboto" !important;        
    }
    .subtitulo{
        color: rgb(0, 0, 0);
        font-size: 14px;
        font-family: "Roboto";
    }
    .subir, .subir:focus {
        height: 35px;
        width: 100px;
        color: #db2491;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 16px;
        border: 1px solid #db2491;
        border-radius: 5px;
        background-color: #fff;
        margin-top: 7px;
      }
      .subir:hover, .subir:disabled, .subir:disabled:hover  {
        color: #db2491;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 16px;
        border: 1px solid #db2491;
        border-radius: 5px;
        background-color: #fff;
        margin-top: 7px;
      }
      .agregar {
        height: 32px;
        width: 90px;      
        color: #db2491;   
        padding-left: 0px;       
        font-family: "Roboto";
        //font-weight: 400;
        font-size: 15px;
        border: 1px solid #db2491;
        border-radius: 5px;
        background-color: #fff;   
        margin-left: 35%;
      }
      .guardarc {
        height: 35px;
        width: 160px;
        color: #fff;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 16px;
        border: 1px solid #db2491;
        border-radius: 5px;
        background-color: #db2491;
      }
      .guardarc:hover {
        height: 35px;
        width: 160px;
        color: #fff;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 16px;
        border: 1px solid #e149a4;
        border-radius: 5px;
        background-color: #e149a4;
      }
      .guardarc:active {
        height: 35px;
        width: 160px;
        color: #fff;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 16px;
        border: 1px solid #d5007f;
        border-radius: 5px;
        background-color:#d5007f;
        box-shadow: inset 0 0 6px 5px #b7006d;
      }
      .cancelarc {
        color: #db2491;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 16px;
        border: 1px solid #db2491;
        border-radius: 5px;
        background-color: #fff;
        margin-top: 7px;
        height: 35px;
        width: 160px;
        }

      .cancelarc:hover {
        border: 1px solid #e149a4;
        border-radius: 5px;
      }

      .cancelarc:active {
        border: 1px solid #d5007f;
        border-radius: 5px;
        box-shadow: inset 0 0 4px 3px #b7006d;
      }
}