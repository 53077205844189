.titulo-seguimiento {
    color: rgb(0, 0, 0);
    font-size: 24px;
    font-weight: bold;
}

.texto-14-seguimiento {
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: lighter;
}

.texto-16-seguimiento {
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: normal;
}

.texto-16-bold-seguimiento {
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: bold;
}

.texto-18-seguimiento {
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-weight: normal;
}

.rojo-seguimiento {
    color: #ff0000 !important;
}

.header-tabla-gris {
    background-color: #494949;
    border-color: #494949;
}

.roboto-normal-16-white {
    font-size: 16px;
    color: #ffffff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: normal;
}

.texto-centrado {
    text-align: center;
}

.ant-btn.boton-circular-rosa {
    padding-right: 25px;
    padding-left: 9px;
    margin-right: 5%;
    text-align: center;
    border-radius: 100% !important;
    border-color: #d5007f;
    color: #d5007f;
    background-color: #ffffff;
    width: 1%;
}

.ant-btn.boton-circular-rosa:hover {
    padding-right: 25px;
    padding-left: 9px;
    margin-right: 5%;
    text-align: center;
    border-radius: 100% !important;
    border-color: #d5007f;
    color: #d5007f;
    background-color: #ffffff;
    width: 1%;
}

.ant-btn.boton-circular-rosa:focus {
    padding-right: 25px;
    padding-left: 9px;
    margin-right: 5%;
    text-align: center;
    border-radius: 100% !important;
    border-color: #d5007f;
    color: #d5007f;
    background-color: #ffffff;
    width: 1%;
}

.ant-btn.boton-circular-rosa:disabled {
    padding-right: 25px;
    padding-left: 9px;
    margin-right: 5%;
    text-align: center;
    border-radius: 100% !important;
    border-color: #d5007f;
    color: #d5007f;
    background-color: #ffffff;
    width: 1%;
    opacity: 0.5;
}

.icono-boton-circular {
    font-size: 12px;
}

.ant-progress-bg.bar-rojo-seguimiento {
    background-color: #AB2525 !important;
}

.ant-progress-bg.bar-verde-seguimiento {
    background-color: #389b73 !important;
}

.ant-progress-bg.bar-azul-seguimiento {
    background-color: #1890ff !important;
}

.ant-list-pagination {
    text-align: center !important;
}

.filtro-header {
    margin-bottom: 10px;
    margin-top: 10px;
}

.ant-modal-footer {
    display: none;
}

.ant-modal-content {
    width: 800px;
    height: 300px;
}

.titulo-rosa {
    color: #d5007f;
    font-size: 18px;
    font-weight: bold;
}

.button-submit {
    border-color: #D5007f;
    color: #fff;
    background-color: #D5007f;
    padding-left: 40px;
    padding-right: 40px
}

.button-submit:focus {
    border-color: #D5007f;
    color: #fff;
    background-color: #D5007f;
    padding-left: 40px;
    padding-right: 40px
}

.button-submit:hover {
    border-color: #D5007f;
    color: #fff;
    background-color: #D5007f;
    padding-left: 40px;
    padding-right: 40px
}

.button-cancel {
    border-color: #D5007f;
    color: #D5007f;
    background-color: #fff;
    padding-left: 40px;
    padding-right: 40px
}

.button-cancel:focus {
    border-color: #D5007f;
    color: #D5007f;
    background-color: #fff;
    padding-left: 40px;
    padding-right: 40px
}

.button-cancel:hover {
    border-color: #D5007f;
    color: #D5007f;
    background-color: #fff;
    padding-left: 40px;
    padding-right: 40px
}

.button-link {
    color: #D5007f;
}

.button-link:focus {
    color: #D5007f;
}

.button-link:hover {
    color: #D5007f;
}

.boton-ok {
    color: #389b73 !important;
    background-color:  #fff;
    border-color: #fff
}

.boton-ok:focus {
    color: #389b73 !important;
    background-color:  #fff;
    border-color: #fff
}

.boton-ok:hover {
    color: #389b73 !important;
    background-color:  #fff;
    border-color: #fff
}

.boton-ok:disabled {
    color: #389b73 !important;
    background-color:  #fff;
    border-color: #fff;
    opacity: 60%;
}

.boton-cancelar {
    color: #AB2525 !important;
}

.boton-cancelar:focus {
    color: #AB2525 !important;
}

.boton-cancelar:hover {
    color: #AB2525 !important;
}

.tamanio-input-tabla {
    width: 50%;
}

.acciones-centrado{
    display: flex;
    text-align: center;
    padding: 0px 6px 1px 6px;
}

.seleccion{
    min-width: 20%;
    width: 22%;
    max-width: 25%;
}