.div_reporteAprobacion {
    .registro_lista {
        display: flex !important;
    }
    .encabezado_lista {
        background-color: rgb(224, 224, 224) !important;
        padding-left: 10px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        padding-right: 15px !important;
        width: 180px;
    }
    .cuerpo_lista {
        padding: 10px !important;        
    }
    .encabezado_acuerdo {
        color: #ffffff;
        background-color: rgb(73, 73, 73) !important;
        padding-left: 20px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
    .encabezado_otro_acuerdo {
        color: #ffffff;
        background-color: rgb(124, 124, 124) !important;
        text-align: center !important;
    }

    .list-aprobacion{
        padding: 0;
    }

}