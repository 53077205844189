.div-botones {
    justify-content: center;
    align-items: center;
    display: flex;       
    margin-top: 10px;   
    margin-left: 10px;
    flex-wrap: wrap;
}

.guardar-evidencia, .guardar-evidencia:focus {
    height: 35px;
    color: #fff;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #db2491;
    margin-left: 10px;
    padding: .0em 3em;
  }

.guardar-evidencia:hover, .guardar-evidencia:disabled, .guardar-evidencia:disabled:hover {
    height: 35px;
    color: #fff;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #e149a4;
    border-radius: 5px;
    background-color: #e149a4;
    margin-left: 10px;
    padding: .0em 3em;
}

.cancelar-evidencia, .cancelar-evidencia:focus {
    height: 35px;
    color: #db2491;     
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #fff;
    padding: .0em 3em;
}

.cancelar-evidencia:hover {
    height: 35px;
    color: #db2491;      
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #fff;
    padding: .0em 3em;
}

.modificar-verificacion, .guardar-verificacion:focus {
    height: 35px;
    color: #fff;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #db2491;
    margin-left: 10px;
    padding: .0em 3em;
  }

.modificar-verificacion:hover, .modificar-verificacion:disabled, .modificar-verificacion:disabled:hover {
    height: 35px;
    color: #fff;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #e149a4;
    border-radius: 5px;
    background-color: #e149a4;
    margin-left: 10px;
    padding: .0em 3em;
}

.descargar-evidencia, .descargar-evidencia:hover, .descargar-evidencia:focus {
    height: 30px;
    color: #db2491;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #fff;
    margin-top: 2px;
}

.descargar-evidencia, .descargar-evidencia:hover, .descargar-evidencia:focus {
    height: 30px;
    color: #db2491;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #fff;
    margin-top: 2px;
}

.ant-switch-checked {
    background-color: #db2491;
}

.ant-switch-inner {
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin-right: 6px;
    margin-left: 24px;
    color: #fff;
    font-size: 14px;
}