.titulo-adjudicacion {
    color: rgb(0, 0, 0);
    font-size: 24px;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.texto-16-adjudicacion{
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: normal;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.texto-16-adjudicacion-modal-bold{
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.texto-16-adjudicacion-modal{
    color: #333333;
    font-size: 16px;
    font-weight: normal;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.texto-14-adjudicacion {
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: lighter;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.texto-18-adjudicacion {
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-weight: normal;
}
.rojo-adjudicacion {
    color: #ff0000 !important;
}

.div-container-table-adjudicacion{
    margin-top: 16px;
}

.div-container-notificar{
    display: flex;
    margin-top: 16px;
    justify-content: flex-end;
}

/*.button-notificar {
    height: 35px;
    width: 160px;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #db2491;
    
}
  
.button-notificar:hover {
    border: 1px solid #e149a4;
    border-radius: 5px;
    color: #fff;
    background-color: #e149a4;
}

.button-notificar:active {
    border: 1px solid #d50071;
    border-radius: 5px;
    color: #fff;
    background-color:#d50071;
    //box-shadow: inset 0 0 6px 5px #b7006d;
}

.button-notificar:disabled, .button-notificar:disabled:hover {
    border: 1px solid #d50071;
    border-radius: 5px;
    color: #fff;
    background-color:#d50071;
    box-shadow: inset 0 0 6px 5px #b7006d;
}

.button-notificar:focus{
    border: 1px solid #e149a4;
    border-radius: 5px;
    color: #fff;
    background-color: #e149a4;
}*/

.button-notificar, .button-notificar:focus {
    height: 35px;
    width: 160px;
    color: #fff;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #db2491;
  }

  .button-notificar:hover, .button-notificar:disabled, .button-notificar:disabled:hover {
    height: 35px;
    width: 160px;
    color: #fff;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #e149a4;
    border-radius: 5px;
    background-color: #e149a4;
}

.ilist {
    color: #db2491;
    border: 1px solid #db2491;
    background-color: #fff;
    padding: 0px 6px 1px 6px;
}
.ilist:disabled {
    color: #db2491;
    border: 1px solid #db2491;
    background-color: #fff;
    opacity: 0.5;
}
.ilist:focus {
    color: #db2491;
    border: 1px solid #db2491;
    background-color: #fff;
    padding: 0px 6px 1px 6px;
}
.ilist:hover {
    color: #db2491;
    border: 1px solid #db2491;
    background-color: #fff;
    padding: 0px 6px 1px 6px;
}

.ilist:disabled:hover {
    color: #db2491;
    border: 1px solid #db2491;
    background-color: #fff;
    padding: 0px 6px 1px 6px;
}

.div-acciones{
    display: flex;
    text-align: center;
    padding: 0px 6px 1px 6px;
}

.dcolor{
    color: #fff;
}

.div_cargando {
    display: flex;
    flex-direction: column;  
    font-size: 28px;
}

.contenidoJustificacion{
    padding: 14px 14px 14px 14px;
}

.ant-modal-header {
    background: #fff !important;
    border-bottom: 0px solid #e8e8e8 !important;
    border-radius: 1px 1px 0 0 !important;
}

.ant-modal-title {
    font-size: medium;
    font-family: Roboto;
    color: #d5007f !important;
    font-size: 18px !important;
}

.ant-modal-footer {
    display: flex;
    text-align: center;
    padding: 0px 16px;
    background: transparent;
    border-top: 0px solid #e8e8e8;
    border-radius: 0 0 0px 0px
}

.ant-modal-body {
    padding: 24px;
    font-size: 16px;
    color: #333333;
    line-height: 1.5;
    word-wrap: break-word;
}

.ant-form-item-required{
    color: #333333 !important;
    font-size: 16px !important;
    font-weight: normal !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.boton-modal-aceptar, .boton-modal-aceptar:focus {
    color: #fff;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #db2491;
  }

.boton-modal-aceptar:hover, .boton-modal-aceptar:disabled, .boton-modal-aceptar:disabled:hover {
    color: #fff;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #e149a4;
    border-radius: 5px;
    background-color: #e149a4;
}

.boton-modal-cancelar, .boton-modal-cancelar:focus {   
    color: #db2491;       
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #fff;
}

.boton-modal-cancelar:hover {    
    color: #db2491;   
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #fff;
}

.ant-modal-close-x {
    display: block;
    width: 56px;
    height: 56px;
    font-size: 16px;
    font-style: initial;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
}

.ant-modal-content {
    width: auto !important;
    height: auto !important;
}