.customViewer {
  border-color: #fa5b35;
  border-width: 1px;
  border-style: solid;
}
.customViewer > canvas {
  width:500px;
  height: 500px;
}
.customPrevBtn {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  width: 48px;
  color: #fa5b35;
  background-color: #f3f3f3;
  border-color: #fa5b35;
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
}
.customNextBtn {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  width: 48px;
  color: #fa5b35;
  background-color: #f3f3f3;
  border-color: #333333;
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
}
.customPages {
  display: inline-block;
  width: calc(100% - 2 * 50px);
}
.customWrapper {
  height: 40px;
  font-size: 24px;
  padding: 12px;
  padding-bottom: 0;
  background-color: #fff;
}
#regresar {
  color:#db2491;
  font-size: 16px;
  align-items: flex-start;
  font-weight: 400;
  text-decoration-line: underline;
  text-decoration-color: #db2491;
  text-decoration-style: solid ;
}
h3{
  color:#333;
  font-size: 18px;
  font-weight: 600;
}
#smsg {
  color:#db2491;
}
#msg {
  color:#333;
  font-weight: 400;
  font-size: 16px;
}
#contb{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
#contd{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
#btonc {
  height: 35px;
  width: 160px;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  border: 1px solid #db2491;
  border-radius: 5px;
  background-color: #db2491;
  
  }

#btonc:hover {
  border: 1px solid #e149a4;
  border-radius: 5px;
  background-color: #e149a4;
}
#btonc:active {
  border: 1px solid #d50071;
  border-radius: 5px;
  background-color:#d50071;
  box-shadow: inset 0 0 6px 5px #b7006d;
}
.alineacion-div {
  display: flex;
  align-items:center;
  justify-content: flex-end;
}
#ilist {
  color: #db2491;
  border: 1px solid #db2491;
  background-color: #fff;
  padding: 0px 6px 1px 6px;
}
#ilist:disabled {
  color: #db2491;
  border: 1px solid #db2491;
  background-color: #fff;
  opacity: 0.5;
}

.subir {
  height: 35px;
  width: 200px;
  color: #db2491;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  border: 1px solid #db2491;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 7px;
}
.subir:hover {
  height: 35px;
  width: 200px;
  color: #db2491;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  border: 1px solid #db2491;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 7px;
}
.subir:disabled, .subir:disabled:focus, .subir:disabled:active, .subir:disabled:hover {
  height: 35px;
  width: 200px;
  color: #db2491;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  border: 1px solid #db2491;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 7px;
}

.principal-div {
  display: flex;
  align-items:center;
  justify-content: center;
  flex-wrap: wrap;
}

.div-guardar{
  display: flex;
  align-items:center;
  justify-content: center;
}

.div_boton_descargar_evidencia{
  margin-top: 25px !important; 
  display: flex;
  align-items:flex-end;
  justify-content: flex-end;
}

.descargar, .descargar:hover, .descargar:active, .descargar:focus  {
  height: 35px;
  width: 150px;
  color: #db2491;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  border: 1px solid #db2491;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 7px;
  
}

.descargar_evidencia {
  height: 35px;
  width: 200px;
  color: #db2491;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  border: 1px solid #db2491;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 7px;
  
  }
.descargar_evidencia:hover {
  height: 35px;
  width: 200px;
  color: #db2491;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  border: 1px solid #db2491;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 7px;
}
.descargar_evidencia:active {
  height: 35px;
  width: 200px;
  color: #db2491;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  border: 1px solid #db2491;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 7px;
}

.contenidoJustificacion{
    padding: 14px 14px 14px 14px;
}

.ant-modal-header {
    background: #fff !important;
    border-bottom: 0px solid #e8e8e8 !important;
    border-radius: 1px 1px 0 0 !important;
}

.ant-modal-title {
    font-size: medium;
    font-family: Roboto;
    color: #d5007f !important;
    font-size: 18px !important;
}

.ant-modal-footer {
    display: inline !important;
    display: flex;
    text-align: center;
    padding: 0px 16px;
    background: transparent;
    border-top: 0px solid #e8e8e8;
    border-radius: 0 0 0px 0px
}

.ant-modal-body {
    padding: 24px;
    font-size: 16px;
    color: #333333;
    line-height: 1.5;
    word-wrap: break-word;
}

.ant-form-item-required{
    color: #333333 !important;
    font-size: 16px !important;
    font-weight: normal !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.boton-modal-aceptar, .boton-modal-aceptar:focus {
    color: #fff;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #db2491;
  }

.boton-modal-aceptar:hover, .boton-modal-aceptar:disabled, .boton-modal-aceptar:disabled:hover {
    color: #fff;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #e149a4;
    border-radius: 5px;
    background-color: #e149a4;
}

.boton-modal-cancelar, .boton-modal-cancelar:focus {   
    color: #db2491;       
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #fff;
}

.boton-modal-cancelar:hover {    
    color: #db2491;   
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #fff;
}

.ant-modal-close-x {
    display: block;
    width: 56px;
    height: 56px;
    font-size: 16px;
    font-style: initial;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
}

.ant-modal-content {
    width: auto !important;
    height: auto !important;
}


.text-izquierda{
  text-align: left;
}