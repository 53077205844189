.ant-layout-sider {
  background-color:  #f0f2f5 !important;
  border-right: 1px solid #999 !important;  
}
.ant-layout-sider-trigger {
    /*width: 50px !important;
    border-radius: 250px;
    margin: 0 200px;*/
    padding: 24 !important;
    position: absolute !important;
    bottom: 0 !important;
    z-index: 1 !important;
    height: 48px !important;
    color: #fff !important;
    line-height: 48px !important;//Centar la flecha
    text-align: center !important;
    background: #d5007f !important; //color del boton
    cursor: pointer !important;
    -webkit-transition: all 0.2s !important;
    transition: all 0.2s !important;
  //poner el boton en la paerte top
    top: 0em;
    right: 50;
    bottom: 3em;
    left: 0;       
    
}
.ant-menu-inline.ant-menu-item-selected::after {
  border-right-color: #fff !important;  
}
.ant-menu-item-selected {
  background-color: #fff !important;  
  color: #d5007f !important;  
}
.ant-menu {
  background-color: #f0f2f5 !important;
}
.ant-menu-item:hover {
  color: #d5007f !important;
}
.ant-menu-submenu-title {
  color: #000 !important;
}
.ant-menu-submenu-title:hover {
  color: #d5007f !important;
}
.div_boton_reportes{
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: flex-start !important;
  margin-top: 40px !important;

}
.reportes{
  height: 35px !important;
  width: 120px !important;
  color: #db2491 !important;
  font-family: "Roboto" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  border: 1px solid #db2491 !important;
  border-radius: 5px !important;
  background-color: #f0f2f5 !important;
  margin-top: 7px !important;
}
.reportes:hover, .reportes:disabled, .reportes:disabled:hover  {
  color: #db2491 !important;
  font-family: "Roboto" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  border: 1px solid #db2491 !important;
  border-radius: 5px !important;
  background-color: #f0f2f5 !important;
  margin-top: 7px !important;
}

.ayuda{
  height: 35px !important;
  width: 120px !important;
  color: #db2491 !important;
  font-family: "Roboto" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
   background-color: #f0f2f5 !important;
  margin-top: 7px !important;
}
.ayuda:hover, .ayuda:disabled, .ayuda:disabled:hover  {
  color: #d5007f !important;
  font-family: "Roboto" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  background-color: #f0f2f5 !important;
  margin-top: 7px !important;
}


  