.etiqueta {
  color: black;
  line-height: 1.5;
}
.proceso {
  text-align: right;
  color: #e149a4;
  text-decoration: underline;
  width: 32%;
  margin-left: 65%;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
}
.anticon {
  font-size: 15px;
}

p{
  padding-top: -10px;
}
