

.reporte {
 border-style: solid;
 border-width: 1px;
 border-color: #807b7b;
 padding-top: 5px;
 padding-left: 15px;
 padding-right: 15px;
 padding-bottom: 15px;

}


.subreporte {
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #c4c0c0;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
   
   }

.titulo-main-reportes {
    color: rgb(0, 0, 0);
    font-size: 25px;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.subtitulo-reportes {
    color: rgb(0, 0, 0);
    font-size: 25px;
    font-weight: lighter;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.subtitulo-subreportes {
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-weight: lighter;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.titulo-reportes {
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-weight: bold;
}

.titulo-subreportes {
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: bold;
    padding-top: 10px;
    padding-left: 10px;
}

.texto-12-reportes {
    color: #333333;
    font-size: 12px;
    font-weight: lighter;
}

.texto-16-reportes {
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: normal;
}

.texto-16-bold-reportes {
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: bold;
}

.texto-18-reportes {
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-weight: normal;
}

.rojo-reportes {
    color: #ff0000 !important;
}

.header-tabla-gris {

        color: #ffffff;
        background-color: rgb(73, 73, 73) !important;
        padding-left: 20px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        width: auto;
    

}

.subheader-tabla {
    color: #ffffff;
    background-color: #828282;
    border-color: #494949;
    padding-top: 7px;
    padding-bottom: 7px;
}

.blanco{
    color: #ffffff;
}


.roboto-normal-16-white {
    font-size: 16px;
    color: #ffffff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: normal;
}

.roboto-bold-16-white {
    font-size: 16px;
    color: #ffffff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
}

.texto-centrado {
    text-align: center;
}

.texto-izquierda {
    text-align: left;
}

.margen-izquierda-10{
    padding-left: 10px;
}

.texto-derecha {
    text-align: right;
}

.fila{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    font-size: 16px;
    color: #333333;


}

.ant-btn.boton-circular-rosa {
    padding-right: 25px;
    padding-left: 9px;
    margin-right: 5%;
    text-align: center;
    border-radius: 100% !important;
    border-color: #d5007f;
    color: #d5007f;
    background-color: #ffffff;
    width: 1%;
}

.ant-btn.boton-circular-rosa:hover {
    padding-right: 25px;
    padding-left: 9px;
    margin-right: 5%;
    text-align: center;
    border-radius: 100% !important;
    border-color: #d5007f;
    color: #d5007f;
    background-color: #ffffff;
    width: 1%;
}

.ant-btn.boton-circular-rosa:focus {
    padding-right: 25px;
    padding-left: 9px;
    margin-right: 5%;
    text-align: center;
    border-radius: 100% !important;
    border-color: #d5007f;
    color: #d5007f;
    background-color: #ffffff;
    width: 1%;
}

.icono-boton-circular {
    font-size: 12px;
}

.ant-progress-bg.bar-rojo-reportes {
    background-color: #AB2525 !important;
}

.ant-progress-bg.bar-verde-reportes {
    background-color: #389b73 !important;
}

.ant-progress-bg.bar-azul-reportes {
    background-color: #1890ff !important;
}

.ant-list-pagination {
    text-align: center !important;
}

.filtro-header {
    margin-bottom: 10px;
    margin-top: 10px;
}

.ant-modal-footer {
    display: none;
}

.ant-modal-content {
    width: 800px;
    height: 300px;
}

.titulo-rosa {
    color: #d5007f;
    font-size: 18px;
    font-weight: bold;
}

.button-submit {
    border-color: #D5007f;
    color: #fff;
    background-color: #D5007f;
    padding-left: 40px;
    padding-right: 40px
}

.button-submit:focus {
    border-color: #D5007f;
    color: #fff;
    background-color: #D5007f;
    padding-left: 40px;
    padding-right: 40px
}

.button-submit:hover {
    border-color: #D5007f;
    color: #fff;
    background-color: #D5007f;
    padding-left: 40px;
    padding-right: 40px
}

.button-cancel {
    border-color: #D5007f;
    color: #D5007f;
    background-color: #fff;
    padding-left: 40px;
    padding-right: 40px
}

.button-cancel:focus {
    border-color: #D5007f;
    color: #D5007f;
    background-color: #fff;
    padding-left: 40px;
    padding-right: 40px
}

.button-cancel:hover {
    border-color: #D5007f;
    color: #D5007f;
    background-color: #fff;
    padding-left: 40px;
    padding-right: 40px
}

.button-link {
    color: #D5007f;
}

.button-link:focus {
    color: #D5007f;
}

.button-link:hover {
    color: #D5007f;
}

.boton-ok {
    color: #389b73 !important;
    background-color:  #fff;
    border-color: #fff
}

.boton-ok:focus {
    color: #389b73 !important;
    background-color:  #fff;
    border-color: #fff
}

.boton-ok:hover {
    color: #389b73 !important;
    background-color:  #fff;
    border-color: #fff
}

.boton-cancelar {
    color: #AB2525 !important;
}

.boton-cancelar:focus {
    color: #AB2525 !important;
}

.boton-cancelar:hover {
    color: #AB2525 !important;
}

.tamanio-input-tabla {
    width: 50%;
}


.div_boton_descarga{
    display: flex !important;
    flex-direction: row !important;
    justify-content: right !important;
    align-items: flex-start !important;
    margin-top: 40px !important;
  
  }
  .descarga{
    height: 35px !important;
    width: 120px !important;
    color: #db2491 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400 !important;
    font-size: 16px !important;
    border: 1px solid #db2491 !important;
    border-radius: 5px !important;
    background-color: #f0f2f5 !important;
    margin-top: 7px !important;
  }
  .descarga:hover, .descarga:disabled, .descarga:disabled:hover  {
    color: #db2491 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400 !important;
    font-size: 16px !important;
    border: 1px solid #db2491 !important;
    border-radius: 5px !important;
    background-color: #f0f2f5 !important;
    margin-top: 7px !important;
  }
  

  .header-tabla-reportes th{
    background-color: #494949;
    border-color: #494949;
}