.div_creararticulo {
  
  display: flex !important;  
  flex-direction: column !important;       
  .div_titulo {
      justify-content: left !important;
      margin-bottom: 20px !important;
  }   
  .div_nombrearticulo {
    justify-content: start !important;
    align-items: center !important;
    min-width: 300px !important; 
    width: 500px !important;
    margin-bottom: 25px !important; 
  }
  .div_diseniounico{
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    margin-bottom: 60px !important;        
  }
  .div_boton_agregar{
    display: flex !important; //No toma este valor
    justify-content: right !important; //No toma este valor
  }

  .div_botones_agregar{
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;    
  }
  .div_botones_finales{ 
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-around !important;
    align-items: center !important;      
  }
  .encabezado {
      color: rgb(0, 0, 0);
      font-size: 24px !important;
      font-family: "Roboto" !important;        
  }
  .subtitulo{
      color: rgb(0, 0, 0);
      font-size: 14px;
      font-family: "Roboto";
  }
  
    .guardarc {
      height: 35px;
      width: 160px;
      color: #fff;
      font-family: "Roboto";
      font-weight: 400;
      font-size: 16px;
      border: 1px solid #db2491;
      border-radius: 5px;
      background-color: #db2491;
    }
    .guardarc:hover {
      height: 35px;
      width: 160px;
      color: #fff;
      font-family: "Roboto";
      font-weight: 400;
      font-size: 16px;
      border: 1px solid #e149a4;
      border-radius: 5px;
      background-color: #e149a4;
    }
    .guardarc:active {
      height: 35px;
      width: 160px;
      color: #fff;
      font-family: "Roboto";
      font-weight: 400;
      font-size: 16px;
      border: 1px solid #d5007f;
      border-radius: 5px;
      background-color:#d5007f;
      box-shadow: inset 0 0 6px 5px #b7006d;
    }
}

.div_consultasesion {
display: flex;
flex-direction: column;  
font-size: 16px;
.div_titulo {
    justify-content: left;
    margin-bottom: 20px;   
    .encabezado {
      color: rgb(0, 0, 0);
      font-size: 28px;
      font-family: "Roboto";
  }     
}    
.div_enca_titulos {
    justify-content: space-between; //Alinea los componentes a los extremos de la misma línea.
    display: flex;
    flex-direction: row;    
    margin-bottom: 10px;   
}
.div_tablas {
    margin-bottom: 70px; 
}

.titulo{
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-family: "Roboto";
}

.div_agregar_sesion{
  font-size: 16px;
   .div_sesion{
       display: flex !important;
       flex-direction: row !important;
       justify-content: center !important;
       align-items: flex-start !important;
       margin-bottom: 60px !important;     
       
     
     .div_sesion_col{   
       justify-content: space-around !important;
       width: 25% !important; 
       align-items: center;
       .subir {
      
        height: 30px;
        width: 55%;
        color: #db2491;
        font-size: 14px;
        font-weight: 600;
        border: 1px solid #db2491;
        border-radius: 5px;
        background-color: #fff;
        margin-top: 2px;
      }
       #ilist {
        border: 1px solid #db2491;
        background-color: #fff;
        padding: 0px 6px 1px 6px;
        height:  30px;
        width: 100%;
      }
     }
   }
 }
#agregar {
    display: flex;
    flex-direction: row;   
    justify-content: space-around ;
    font-weight: 600;
    align-items: center; 
    margin-bottom: 10px;  
    height: 30px;
    width: 90px;      
    color: #db2491;   
    padding-left: 0px;       
    font-size: 14px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #fff;   
    
}
.modificar_catalogos {
    height: 32px;
    width: 90px;      
    color: #db2491;   
    padding-left: 0px;       
    font-family: "Roboto";
    //font-weight: 400;
    font-size: 14px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #fff;   
}

.modificar_catalogos {
    height: 35px;
    width: 180px;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #db2491;
    
    }
  
  .modificar_catalogos:hover {
    border: 1px solid #e149a4;
    border-radius: 5px;
    background-color: #e149a4;
  }
  .modificar_catalogos:active {
    border: 1px solid #d50071;
    border-radius: 5px;
    background-color:#d50071;
    box-shadow: inset 0 0 6px 5px #b7006d;
  }
  .div_boton_modificar{
    margin-top: 25px !important; 
    display: flex;
    align-items:center;
    justify-content: center;
  }
}
.acciones {
display: flex;
text-align: center;
padding: 0px 6px 1px 6px;
}

#tipo{

font-size: 16px;

}


.div_consultarsesion {
display: flex !important;  
flex-direction: column !important;
flex-Wrap: wrap;
align-items:center;
justify-content: center;
.div_infosesion{
  justify-content: center !important;
  display: flex; 
  flex-direction: column; 
  flex-Wrap: wrap;
  .div_titulo {
      justify-content: left !important;
      margin-bottom: 20px !important;
  }   
  .div_nombreacuerdo {
    justify-content: left !important;
    align-items: left !important;
    min-width: 300px !important; 
    width: 900px !important;
    margin-bottom: 25px !important; 
  }
  .div_archivo{
    height: 900px !important;
    width: 900px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .div_boton_descargar{
    justify-content: flex-end !important;
    align-items: left !important;
    display: flex !important; //No toma este valor
    margin-top: 15px !important; 
    margin-bottom: 15px !important; 
  }
}
}
.div_boton_modificar{
margin-top: 25px !important; 
display: flex;
align-items:center;
justify-content: center;
}

.principal-div {
display: flex;
align-items:center;
justify-content: center;
flex-wrap: wrap;
}
.div_especificaciones{
margin-top: 25px !important; 
display: flex;
align-items:center;
justify-content: center;
}

.descargar {
height: 35px;
width: 150px;
color: #db2491;
font-family: "Roboto";
font-weight: 400;
font-size: 16px;
border: 1px solid #db2491;
border-radius: 5px;
background-color: #fff;
margin-top: 7px;

}
.descargar:hover {
height: 35px;
width: 150px;
color: #db2491;
font-family: "Roboto";
font-weight: 400;
font-size: 16px;
border: 1px solid #db2491;
border-radius: 5px;
background-color: #fff;
margin-top: 7px;
}
.descargar:active {
height: 35px;
width: 150px;
color: #db2491;
font-family: "Roboto";
font-weight: 400;
font-size: 16px;
border: 1px solid #db2491;
border-radius: 5px;
background-color: #fff;
margin-top: 7px;
}


.div_agregar_sesion{
font-size: 16px;
 .div_sesion{
     display: flex !important;
     flex-wrap: initial !important;
     justify-content: initial!important;
     align-items: flex-start !important;
     margin-bottom: 60px !important;     
     
   
   .div_sesion_col{   
     text-align: center;
     font-weight: bolder;
     justify-content: space-around !important;
     width: 30% !important; 
     align-items: center;
     .subir {
    
      height: 30px;
      width: 55%;
      color: #db2491;
      font-size: 14px;
      font-weight: 600;
      border: 1px solid #db2491;
      border-radius: 5px;
      background-color: #fff;
      margin-top: 2px;
    }
     #ilist {
      border: 1px solid #db2491;
      background-color: #fff;
      padding: 0px 6px 1px 6px;
      height:  30px;
      width: 100%;
    }
    #text{
      width: 90%;
      border: none;
      text-align: center;
    }
   }
   .div_sesion_col2{   
    text-align: center;
    font-weight: bolder;
    justify-content: space-around !important;
    width: 50% !important; 
    align-items: center;
    .subir {
   
     height: 30px;
     width: 55%;
     color: #db2491;
     font-size: 14px;
     font-weight: 600;
     border: 1px solid #db2491;
     border-radius: 5px;
     background-color: #fff;
     margin-top: 2px;
   }
    #ilist {
     border: 1px solid #db2491;
     background-color: #fff;
     padding: 0px 6px 1px 6px;
     height:  30px;
     width: 100%;
   }
   #text{
     width: 90%;
     border: none;
     text-align: center;
   }
  }
 }
}
.div_titulo {
justify-content: left;
margin-bottom: 20px;   
.encabezado {
  color: rgb(0, 0, 0);
  font-size: 28px;
  font-family: "Roboto";
}     
}


.div_modificarsesion {
  display: flex !important;  
  flex-direction: column !important;       
  .div_titulo {
      justify-content: left !important;
      margin-bottom: 20px !important;
      .encabezado {
        color: rgb(0, 0, 0);
        font-size: 28px;
        font-family: "Roboto";
      }    
  }   
  .div_nombresesion {
    justify-content: start !important;
    align-items: center !important;
    min-width: 300px !important; 
    width: 500px !important;
    margin-bottom: 25px !important; 
  }
  .div_archivoSesion{
    height: 120px !important;
    width: 900px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;      
  }
  .div_boton_agregar{
    display: flex !important; //No toma este valor
    justify-content: right !important; //No toma este valor
  }
 
  .div_botones_agregar{
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;    
  }
  .div_botones_finales{ 
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-around !important;
    align-items: center !important;      
  }
  .div_boton_descargar{
    justify-content: left !important;
    align-items: left !important;
    display: flex !important; //No toma este valor
    justify-content: right !important; //No toma este valor
  }
  .encabezado {
      color: rgb(0, 0, 0);
      font-size: 24px !important;
      font-family: "Roboto" !important;        
  }
  .subtitulo{
      color: rgb(0, 0, 0);
      font-size: 14px;
      font-family: "Roboto";
  }
  .subir {
      height: 35px;
      width: 155px;
      color: #db2491;
      font-family: "Roboto";
      font-weight: 400;
      font-size: 16px;
      border: 1px solid #db2491;
      border-radius: 5px;
      background-color: #fff;
      margin-top: 7px;
    }
    .guardarc {
      height: 35px;
      width: 160px;
      color: #fff;
      font-family: "Roboto";
      font-weight: 400;
      font-size: 16px;
      border: 1px solid #db2491;
      border-radius: 5px;
      background-color: #db2491;
    }
    .guardarc:hover {
      height: 35px;
      width: 160px;
      color: #fff;
      font-family: "Roboto";
      font-weight: 400;
      font-size: 16px;
      border: 1px solid #e149a4;
      border-radius: 5px;
      background-color: #e149a4;
    }
    .guardarc:active {
      height: 35px;
      width: 160px;
      color: #fff;
      font-family: "Roboto";
      font-weight: 400;
      font-size: 16px;
      border: 1px solid #d5007f;
      border-radius: 5px;
      background-color:#d5007f;
      box-shadow: inset 0 0 6px 5px #b7006d;
    }
}


.titulo-sesion {
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.texto-14-sesion {
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-weight: lighter;
  text-align: center;

}
.texto-16-sesion {
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-weight: normal;
  text-align: center;
}
.texto-18-sesion {
  color: rgb(0, 0, 0);
  font-size: 18px;
  font-weight: normal;
  text-align: center;
}
.rojo-sesion {
  color: #db2491 !important;
}

.has-success.has-feedback .ant-form-item-children-icon {
  color: #389b73;
  -webkit-animation-name: diffZoomIn1 !important;
  animation-name: diffZoomIn1 !important;
}

.has-error.has-feedback .ant-form-item-children-icon {
  color: #79134c;
  -webkit-animation-name: diffZoomIn1 !important;
  animation-name: diffZoomIn1 !important;
}

.has-success .ant-select-selection{
  border-color: #389b73;
}

.has-error .ant-select-selection{
  border-color: #79134c;
}

.has-success .ant-input {
  background-color: #fff;
  border-color: #389b73;
}

.has-error .ant-input {
  background-color: #fff;
  border-color: #79134c;
}

.has-success .ant-input-number, 
.has-success .ant-time-picker-input {
  background-color: #fff;
  border-color: #389b73;
}

.has-error .ant-input-number, 
.has-error .ant-time-picker-input {
  background-color: #fff;
  border-color: #79134c;
}

.agregar:hover, .agregar:focus  {
  height: 32px;
  width: 100px;      
  color: #db2491;   
  padding-left: 0px;       
  font-family: "Roboto";
  //font-weight: 400;
  font-size: 14px;
  border: 1px solid #db2491;
  border-radius: 5px;
  background-color: #fff;   
}

.agregar {
  height: 32px;
  width: 90px;      
  color: #db2491;   
  padding-left: 0px;       
  font-family: "Roboto";
  //font-weight: 400;
  font-size: 14px;
  border: 1px solid #db2491;
  border-radius: 5px;
  background-color: #fff;   
}
