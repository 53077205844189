.titulo-admin {
    color: rgb(0, 0, 0);
    font-size: 25px;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.subtitulo-admin {
    color: rgb(0, 0, 0);
    font-size: 25px;
    font-weight: lighter;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.titulo-admin-grafica {
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-weight: lighter;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.texto-14-admin {
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: lighter;
}
.texto-16-admin {
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: normal;
}
.texto-18-admin {
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-weight: normal;
}
.ant-tabs.tabs-home {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #333333;
    font-weight: bold;
    font-size: 17px;
}

.ant-tabs-bar.ant-tabs-top-bar{
    margin: 0 0 16px 0;
    float: right;
    border-bottom: 1px solid #e8e8e8;
    outline: none;
    -webkit-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-tabs-nav .ant-tabs-tab-active{
    color: #ce55a0 !important;
    font-weight: bold !important;
}

.ant-tabs-nav .ant-tabs-tab:hover {
    color: #ce55a0 !important;
}

.ant-tabs-ink-bar {
    height: 3px !important;
    background-color: #d5007f !important;
}

.ant-tabs-content .ant-tabs-content-animated .ant-tabs-top-content{
    border: #d5007f !important
}

/*
.carrusel {
    margin-top: 5%;
    margin-left: 5%;
    margin-bottom: 5%;
    height: 100%;
}*/

.div-charts{
    height: 100%;
    width: 100%;
}

.ant-btn.boton-descargar-home, .ant-btn.boton-descargar-home:hover, .ant-btn.boton-descargar-home:focus{
    color: #db2491;   
    //padding-left: 0px;       
    font-family: "Roboto";
    //font-weight: 400;
    font-size: 14px;
    border: 1px solid #db2491;
    //border-radius: 5px;
    background-color: #fff;
}

.anticon.anticon-info-circle.icono-info{
    color: #d5007f;
    padding-left: 2px;
    padding-right: 2px;
}

.ant-select.ant-select-enabled{
    font-size:16px;
    font-weight: 400;
    color: #6d6d6d;
    border: #333;
}