.div_modificararticulo {
    display: flex !important;  
    flex-direction: column !important;   
    //background-color: rgb(100, 100, 100) !important;
    .div_titulo {
        justify-content: left !important;
        margin-bottom: 20px !important;
    }   
    .div_nombrearticulo {
      justify-content: start !important;
      align-items: center !important;
      min-width: 300px !important; 
      width: 500px !important;
      margin-bottom: 25px !important; 
    }
    .div_diseniounico{
      height: 120px !important;
      width: 900px !important;
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;      
    }
    .div_boton_agregar{
      display: flex !important; //No toma este valor
      justify-content: right !important; //No toma este valor
    }
    .div_especificaciones{
      display: flex !important;
      //display: block !important;
      //flex-direction: row !important;
      //justify-content: center !important;
      align-items: flex-start !important;
      //align-items: stretch !important;
      //margin-bottom: 1px !important;  
      width: 100%;
    }
    .div_especificaciones_form{
      display: flex !important;
      width: 96% !important;
    }
    .div_especificaciones_col{   
      //display: flex !important;
      display: inline-block !important;
      //justify-content: space-around !important;
      align-items: flex-start !important;
      width: 96% !important;
      //flex-direction: column !important;
    }
    .div_titulo_especificaciones{
      display: flex !important;
      //flex-direction: row !important;
      width: 100% !important;
      margin-bottom: 10px !important;
      //justify-content: space-between !important;  
    }
    .div_encabezado_especificaciones{
      //display: flex !important;
      //justify-content:  flex-start !important;
      //width: 190px !important;
      width: 100% !important;
    }
    .div_agregar_especificaciones{
      //display: flex !important;
      //justify-content: flex-end !important;
      //width: 110px !important;
      width: 45% !important;
      margin-right: 25px !important;
    }    
    .div_esp{
      //display: flex !important;
      width: 100% !important;
      margin-bottom: 15px !important;
    }
    .div_botones_agregar{
      display: flex !important;
      flex-direction: row !important;
      justify-content: flex-end !important;    
    }
    .div_botones_finales{ 
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-around !important;
      align-items: center !important;      
    }
    .div_boton_descargar{
      justify-content: left !important;
      align-items: left !important;
      display: flex !important; //No toma este valor
      justify-content: right !important; //No toma este valor
    }
    .div_justificacion{
      display: flex !important;
      flex-direction: column !important;
      width: 46%;
      margin-left: 20px !important; 
    }    
    .encabezado {
        color: rgb(0, 0, 0);
        font-size: 24px !important;
        font-family: "Roboto" !important;        
    }
    .subtitulo{
        color: rgb(0, 0, 0);
        font-size: 14px;
        font-family: "Roboto";
    }
    .subir {
        height: 35px;
        width: 155px;
        color: #db2491;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 16px;
        border: 1px solid #db2491;
        border-radius: 5px;
        background-color: #fff;
        margin-top: 7px;
      }
    .guardarc {
        height: 35px;
        width: 160px;
        color: #fff;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 16px;
        border: 1px solid #db2491;
        border-radius: 5px;
        background-color: #db2491;
      }
    .guardarc:hover {
        height: 35px;
        width: 160px;
        color: #fff;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 16px;
        border: 1px solid #e149a4;
        border-radius: 5px;
        background-color: #e149a4;
      }
    .guardarc:active {
        height: 35px;
        width: 160px;
        color: #fff;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 16px;
        border: 1px solid #d5007f;
        border-radius: 5px;
        background-color:#d5007f;
        box-shadow: inset 0 0 6px 5px #b7006d;
      }
    .campoespecificacion {
        width: 85%
      }
    .justificacion {
        display: flex !important;
        justify-content: flex-start !important;
        margin-bottom: 10px !important;
        width: 100% !important;
      }
    .input_especificacion {
      width: 100% !important;
      //width: 400px !important;
      background-color:rgb(240, 240, 240);
    }
    .eliminar {
        width: 40px !important;
        //background-color: rgb(200,200,200) !important;
      }
      .cancelarc {
        color: #db2491;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 16px;
        border: 1px solid #db2491;
        border-radius: 5px;
        background-color: #fff;
        margin-top: 7px;
        height: 35px;
        width: 160px;
        }

      .cancelarc:hover {
        border: 1px solid #e149a4;
        border-radius: 5px;
      }

      .cancelarc:active {
        border: 1px solid #d5007f;
        border-radius: 5px;
        box-shadow: inset 0 0 4px 3px #b7006d;
      }
}