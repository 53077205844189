.div_catalogos {
    display: flex;    
    flex-direction: column;       
    .div_titulo {
        justify-content: left;
        margin-bottom: 20px;
    }    
    .div_documentos {
        justify-content: center;
        align-items: center;
        min-width: 380px;      
        margin-bottom: 60px;
        &__subir{
            justify-content: space-around; //Alinea los componentes dejando los mismos espacios entre ellos y a la orilla.
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            &__docsubir{
                justify-content: left;
                width: 25%;
                min-width: 120px;
            }           
        }
    }
    .div_guardar {
        justify-content: center;
        align-items: center;
        display: flex;       
        min-width: 380px;   
        margin-top: 10px;   
    }

  .encabezado {
    color: rgb(0, 0, 0);
    font-size: 28px;
    font-family: "Roboto";
    }
    .titulo{
        color: rgb(0, 0, 0);
        font-size: 18px;
        font-family: "Roboto";
    }
    .subtitulo{
        color: rgb(0, 0, 0);
        font-size: 14px;
        font-family: "Roboto";
    }
  .subir {
    height: 35px;
    width: 100px;
    color: #db2491;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #fff;
    margin-top: 7px;
  }
  .guardarc {
    height: 35px;
    width: 160px;
    color: #fff;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #db2491;
  }
  .guardarc:hover, .guardarc:disabled, .guardarc:disabled:hover, .guardarc:focus {
    height: 35px;
    width: 160px;
    color: #fff;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #e149a4;
    border-radius: 5px;
    background-color: #e149a4;
  }
  .guardarc:active {
    height: 35px;
    width: 160px;
    color: #fff;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #d5007f;
    border-radius: 5px;
    background-color:#d5007f;
    box-shadow: inset 0 0 6px 5px #b7006d;
  }

  .minfo {
    height: 35px;
    color: #333;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 14px;
    border: 1px solid #fff;
    border-radius: 5px;
    background-color: #db2491;
    display:inline-block;
    text-align: justify;
    padding:5px 10px 10px 10px; 
  }
}