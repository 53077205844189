.titulo-verificacion {
    color: rgb(0, 0, 0);
    font-size: 24px;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.texto-12-verificacion {
    color: #333333;
    font-size: 12px;
    font-weight: lighter;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.texto-14-verificacion {
    color: #333333;
    font-size: 14px;
    font-weight: lighter;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.texto-16-verificacion {
    color: #333333;
    font-size: 16px !important;
    font-weight: normal;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.texto-16-bold-verificacion {
    color: #333333;
    font-size: 16px !important;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.texto-18-verificacion {
    color: #333333;
    font-size: 18px;
    font-weight: normal;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.rojo-verificacion {
    color: #ff0000 !important;
}

.header-1-tabla-gris {
    background-color: #494949;
    border-color: #494949;
    padding: 10px 10px;
}

.header-1-texto-medium-16-white{
    font-size: 16px;
    color: #ffffff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: medium;
}

.header-2-tabla-gris {
    background-color: #7c7c7c;
    border-color: #7c7c7c;
    padding: 5px 10px;
}

.header-2-tabla-gris-empty{
    background-color: #7c7c7c;
    border-color: #7c7c7c;
    padding: 17px 0px;
}

.header-2-texto-normal-16-white{
    font-size: 16px;
    color: #ffffff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: normal;
}

.texto-centrado {
    text-align: center;
}

.col-aspecto{
    font-size: 16px;
    color: #333333;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: normal;
    padding: 0px 20px;
    text-align: justify;
}

.respuesta-col{
    padding: 0px 20px;
}

.subir-evidencia, .subir-evidencia:hover, .subir-evidencia:focus {
    height: 30px;
    color: #db2491;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #fff;
    margin-top: 2px;
    padding: .0em 3em;
}

.has-success.has-feedback .ant-form-item-children-icon {
    color: #389b73;
    -webkit-animation-name: diffZoomIn1 !important;
    animation-name: diffZoomIn1 !important;
}

.has-error.has-feedback .ant-form-item-children-icon {
    color: #79134c;
    -webkit-animation-name: diffZoomIn1 !important;
    animation-name: diffZoomIn1 !important;
}

.has-success .ant-select-selection{
    border-color: #389b73;
}

.has-error .ant-select-selection{
    border-color: #79134c;
}

.has-success .ant-input {
    background-color: #fff;
    border-color: #389b73;
}

.has-error .ant-input {
    background-color: #fff;
    border-color: #79134c;
}

.has-success .ant-input-number, 
.has-success .ant-time-picker-input {
    background-color: #fff;
    border-color: #389b73;
}

.has-error .ant-input-number, 
.has-error .ant-time-picker-input {
    background-color: #fff;
    border-color: #79134c;
}

.ant-modal-header {
    background: #fff !important;
    border-bottom: 0px solid #e8e8e8 !important;
    border-radius: 1px 1px 0 0 !important;
}

.ant-modal-title {
    font-size: medium;
    font-family: Roboto;
    color: #d5007f !important;
    font-size: 18px !important;
}

.ant-modal-footer {
    display: flex;
    text-align: center;
    padding: 0px 16px;
    background: transparent;
    border-top: 0px solid #e8e8e8;
    border-radius: 0 0 0px 0px
}

.ant-modal-body {
    padding: 24px;
    font-size: 16px;
    color: #333333;
    line-height: 1.5;
    word-wrap: break-word;
}

.ant-form-item-required{
    color: #333333 !important;
    font-size: 16px !important;
    font-weight: normal !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.boton-modal-aceptar, .boton-modal-aceptar:focus {
    color: #fff;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #db2491;
  }

.boton-modal-aceptar:hover, .boton-modal-aceptar:disabled, .boton-modal-aceptar:disabled:hover {
    color: #fff;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #e149a4;
    border-radius: 5px;
    background-color: #e149a4;
}

.boton-modal-cancelar, .boton-modal-cancelar:focus {   
    color: #db2491;       
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #fff;
}

.boton-modal-cancelar:hover {    
    color: #db2491;   
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #fff;
}

.ant-modal-close-x {
    display: block;
    width: 56px;
    height: 56px;
    font-size: 16px;
    font-style: initial;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
}

.ant-modal-content {
    width: auto !important;
    height: auto !important;
}

.anticon.anticon-info-circle.icono-info{
    color: #d5007f;
    padding-left: 2px;
    padding-right: 2px;
}

.ant-list-header, .ant-list-footer {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}