.ant-table-pagination.ant-pagination {
    display: flex !important;
    align-items:center !important;
    justify-content: center !important;
    text-align: center !important;
    float: none !important;
}

.tabla-adjudicaciones thead tr th {
    //font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    background-color: #333333;
    border: none;
    vertical-align: middle;
    padding: 5px 5px;
    border-right: 1px solid rgba(0, 0, 0, 0.02);
    font-family: "Roboto", sans-serif !important;
    font-size: 16px;
}

.tabla-adjudicaciones .ant-table-bordered .ant-table-thead > tr > th {
    border-right: 1px solid #333333;
}

.ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid rgba(0, 0, 0, 0.02);
}
  
.tabla-adjudicaciones tbody tr {
    height: 2.188rem;
    border-bottom: 1px solid #242a32;
}
  
.tabla-adjudicaciones .ant-table .ant-table-tbody > tr:hover > td {
    background: unset;
}
  
.tabla-adjudicaciones tbody tr td {
    //font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.5px;
    /*color: #000;
    border: none;*/
    vertical-align: middle;
    padding: 0.5rem;
    font-family: "Roboto", sans-serif !important;
    font-size: 16px;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
    background: #333333;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:focus {
    background: #333333;
}

.boton-accion-circular {
    color: #db2491;
    border: 1px solid #db2491;
    background-color: #fff;
    padding: 0px 6px 1px 6px;
}
.boton-accion-circular:disabled {
    color: #db2491;
    border: 1px solid #db2491;
    background-color: #fff;
    opacity: 0.5;
}
.boton-accion-circular:focus {
    color: #db2491;
    border: 1px solid #db2491;
    background-color: #fff;
    padding: 0px 6px 1px 6px;
}
.boton-accion-circular:hover {
    color: #db2491;
    border: 1px solid #db2491;
    background-color: #fff;
    padding: 0px 6px 1px 6px;
}

.boton-accion-circular:disabled:hover {
    color: #db2491;
    border: 1px solid #db2491;
    background-color: #fff;
    padding: 0px 6px 1px 6px;
}

.boton-edicion-confirmar {
    color: #389b73;
    border: transparent;
    background-color: transparent;
    padding: 0px 6px 1px 6px;
    box-shadow: none;
}
.boton-edicion-confirmar:disabled {
    color: #389b73;
    border: transparent;
    background-color: transparent;
    padding: 0px 6px 1px 6px;
    box-shadow: none;
}
.boton-edicion-confirmar:focus {
    color: #389b73;
    border: transparent;
    background-color: transparent;
    padding: 0px 6px 1px 6px;
    box-shadow: none;
}
.boton-edicion-confirmar:hover {
    color: #389b73;
    border: transparent;
    background-color: transparent;
    padding: 0px 6px 1px 6px;
    box-shadow: none;
}

.boton-edicion-confirmar:disabled:hover {
    color: #389b73;
    border: transparent;
    background-color: transparent;
    padding: 0px 6px 1px 6px;
    box-shadow: none;
}

.boton-edicion-cancelar {
    color: #ff0000;
    border: transparent;
    background-color: transparent;
    padding: 0px 6px 1px 6px;
    box-shadow: none;
}
.boton-edicion-cancelar:disabled {
    color: #ff0000;
    border: transparent;
    background-color: transparent;
    padding: 0px 6px 1px 6px;
    box-shadow: none;
}
.boton-edicion-cancelar:focus {
    color: #ff0000;
    border: transparent;
    background-color: transparent;
    padding: 0px 6px 1px 6px;
    box-shadow: none;
}
.boton-edicion-cancelar:hover {
    color: #ff0000;
    border: transparent;
    background-color: transparent;
    padding: 0px 6px 1px 6px;
    box-shadow: none;
}

.boton-edicion-cancelar:disabled:hover {
    color: #ff0000;
    border: transparent;
    background-color: transparent;
    padding: 0px 6px 1px 6px;
    box-shadow: none;
}

