.renglon-no-adjudicado {
    color: #ffffff;
    background-color: #7c7c7c !important;
    border-color: #7c7c7c !important;
    border-right: 1px solid #7c7c7c !important;
    padding-left: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.ant-table-pagination.ant-pagination {
    display: flex !important;
    align-items:center !important;
    justify-content: center !important;
    text-align: center !important;
    float: none !important;
}

.ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid rgba(0, 0, 0, 0.02);
}

.tabla-reporte-produccion .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters::before {
    background-color: #494949 !important;
}

.tabla-reporte-produccion .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on, .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
    color: #db2491;
}

.tabla-reporte-produccion thead tr th {
    font-size: 12px;
    font-weight: 500;
    font-style: medium;
    font-stretch: medium;
    line-height: medium;
    letter-spacing: medium;
    color: #fff;
    background-color: #494949;
    border: none;
    vertical-align: middle;
    padding: 5px 10px;
    border-right: 1px solid rgba(0, 0, 0, 0.02);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
}

.tabla-reporte-produccion .ant-table-bordered .ant-table-thead > tr > th {
    border-right: 1px solid #494949 !important;
}

.ant-table-bordered .ant-table-tbody > tr > td {
    border-right: transparent !important;
}
  
.tabla-reporte-produccion tbody tr {
    height: 2.188rem;
    border-bottom: 1px solid #494949 !important;
}
  
.tabla-reporte-produccion .ant-table .ant-table-tbody > tr:hover > td {
    background: unset;
}
  
.tabla-reporte-produccion tbody tr td {
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.5px;
    /*color: #000;
    border: none;*/
    vertical-align: middle;
    padding: 0.7rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
}