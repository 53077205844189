.ant-layout-header {
  /*Se sobreescribe las propiedades del componente header a tráves del className ant-layout-header*/
  background: #333333 !important;
  height: 50px !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 16px;
  /*Se configura las img y textos que se encuentran en el header*/
  .menu-top {
    display: flex;
    &__logo {
      svg {
        height: 30px;        
      }
    }
    &__ayuda {
      text-align: right;
      width: 100%;
      p {
        color: rgb(255, 255, 255);
      }
    }
  }
  .user{
    width: 24px;
    margin-left: 15px;
    margin-right: 15px;
  }
  a{
    color: white;
  }
  .ant-btn-link{ //ant-btn.ant-btn-link
    color: #fff;
  }
  .ant-btn-link:hover{ //ant-btn.ant-btn-link
    color: #fff;
    background-color: #d5007f;
  }
}
