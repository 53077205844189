.div_consultacatalogos {
    display: flex;
    flex-direction: column;  
    font-size: 28px;
    .div_titulo {
        justify-content: left;
        margin-bottom: 20px;        
    }    
    .div_enca_titulos {
        justify-content: space-between; //Alinea los componentes a los extremos de la misma línea.
        display: flex;
        flex-direction: row;    
        margin-bottom: 10px;   
    }
    .div_tablas {
        margin-bottom: 70px; 
    }
    .encabezado {
        color: rgb(0, 0, 0);
        font-size: 28px;
        font-family: "Roboto";
    }
    .titulo{
        color: rgb(0, 0, 0);
        font-size: 18px;
        font-family: "Roboto";
    }
    
    .agregar, .agregar:focus {
        height: 32px;
        width: 90px;      
        color: #db2491;   
        padding-left: 0px;       
        font-family: "Roboto";
        //font-weight: 400;
        font-size: 14px;
        border: 1px solid #db2491;
        border-radius: 5px;
        background-color: #fff;   
    }

    .agregar:hover, .agregar:disabled:hover  {
        height: 32px;
        width: 100px;      
        color: #db2491;  
        padding-left: 0px;       
        font-family: "Roboto";
        //font-weight: 400;
        font-size: 14px;
        border: 1px solid #db2491;
        border-radius: 5px;
        background-color: #fff;   
    }

    .agregar:disabled {
        height: 32px;
        width: 100px;      
        color: #db2491; 
        padding-left: 0px;       
        font-family: "Roboto";
        //font-weight: 400;
        font-size: 14px;
        border: 1px solid #db2491;
        border-radius: 5px;
        background-color: #fff;
        opacity: 60% !important;   
    }

    .modificar_catalogos, .modificar_catalogos:focus {
        height: 35px;
        width: 160px;
        color: #fff;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 16px;
        border: 1px solid #db2491;
        border-radius: 5px;
        background-color: #db2491;
      }
    
      .modificar_catalogos:hover, .modificar_catalogos:disabled, .modificar_catalogos:disabled:hover {
        height: 35px;
        width: 160px;
        color: #fff;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 16px;
        border: 1px solid #e149a4;
        border-radius: 5px;
        background-color: #e149a4;
    }

      .div_boton_modificar{
        margin-top: 25px !important; 
        display: flex;
        align-items:center;
        justify-content: center;
      }
}
.acciones {
    display: flex;
    text-align: center;
    padding: 0px 6px 1px 6px;
  }
  