.titulo-adjudicacion {
    color: rgb(0, 0, 0);
    font-size: 24px;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.texto-14-adjudicacion {
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: lighter;
}
.texto-16-adjudicacion {
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: normal;
}
.texto-18-adjudicacion {
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-weight: normal;
}
.rojo-adjudicacion {
    color: #ff0000 !important;
}

.has-success.has-feedback .ant-form-item-children-icon {
    color: #389b73;
    -webkit-animation-name: diffZoomIn1 !important;
    animation-name: diffZoomIn1 !important;
}

.has-error.has-feedback .ant-form-item-children-icon {
    color: #79134c;
    -webkit-animation-name: diffZoomIn1 !important;
    animation-name: diffZoomIn1 !important;
}

.has-success .ant-select-selection{
    border-color: #389b73;
}

.has-error .ant-select-selection{
    border-color: #79134c;
}

.has-success .ant-input {
    background-color: #fff;
    border-color: #389b73;
}

.has-error .ant-input {
    background-color: #fff;
    border-color: #79134c;
}

.has-success .ant-input-number, 
.has-success .ant-time-picker-input {
    background-color: #fff;
    border-color: #389b73;
}

.has-error .ant-input-number, 
.has-error .ant-time-picker-input {
    background-color: #fff;
    border-color: #79134c;
}

.agregar:hover, .agregar:focus  {
    height: 32px;
    width: 100px;      
    color: #db2491;   
    padding-left: 0px;       
    font-family: "Roboto";
    //font-weight: 400;
    font-size: 14px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #fff;   
}

.agregar {
    height: 32px;
    width: 90px;      
    color: #db2491;   
    padding-left: 0px;       
    font-family: "Roboto";
    //font-weight: 400;
    font-size: 14px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #fff;   
}
