.subtitulo-reportes {
    color: rgb(0, 0, 0);
    font-size: 25px;
    font-weight: lighter;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.texto-12-reportes {
    color: #333333;
    font-size: 12px;
    font-weight: lighter;
}

.rojo-reportes {
    color: #ff0000 !important;
}


.texto-18-reportes {
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-weight: normal;
}


.subreporte {
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #c4c0c0;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
   
   }

   .div_boton_descarga{
    display: flex !important;
    flex-direction: row !important;
    justify-content: right !important;
    align-items: flex-start !important;
    margin-top: 40px !important;
  
  }

  .descarga{
    height: 35px !important;
    width: 120px !important;
    color: #db2491 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400 !important;
    font-size: 16px !important;
    border: 1px solid #db2491 !important;
    border-radius: 5px !important;
    background-color: #f0f2f5 !important;
    margin-top: 7px !important;
  }
  .descarga:hover, .descarga:disabled, .descarga:disabled:hover  {
    color: #db2491 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400 !important;
    font-size: 16px !important;
    border: 1px solid #db2491 !important;
    border-radius: 5px !important;
    background-color: #f0f2f5 !important;
    margin-top: 7px !important;
  }
  
  .header-tabla-gris {

    color: #ffffff;
    background-color: rgb(73, 73, 73) !important;
    padding-left: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;


}

.texto-centrado {
    text-align: center;
}

.fila{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    font-size: 16px;
    color: #333333;
    padding-left: 20px !important;

}
