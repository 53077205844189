.titulo-verificacion {
    color: rgb(0, 0, 0);
    font-size: 24px;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.titulo-listado-verificacion {
    color: #333333;
    font-size: 20px;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.contenedor-acciones{
    text-align: center;
}

.ilist, .ilist:focus, .ilist:hover, .ilist:disabled:hover {
    padding-right: 21px;
    padding-left: 10px;
    margin-right: 6%;
    color: #db2491;
    border: 1px solid #db2491;
    background-color: #fff;
    padding: 0px 6px 1px 6px;
}
.ilist:disabled {
    opacity: 0.5;
}

.buscar{
width: 188px;
height: 30px;
font-size: 12px;
margin-bottom: 9px;
display: block;
}

.buscarbtn{
    width: 90px;
    border: 1px solid #db2491;
    background-color: #db2491;
    }

.buscarbtn:hover{
    
    border: 1px solid #e149a4;
    background-color: #e149a4;
    }

.buscarbtn:active{
    
        border: 1px solid #d5007f;
        background-color: #e149a4;
        box-shadow: inset 0 0 6px 5px #b7006d;
        }

        .reiniciarbtn{
            width: 90px;
            border: 1px solid #db2491;
            background-color: #ffffff;
            margin-inline-start: 3px;
            color: #db2491;
            }
        
        .reiniciarbtn:hover{
            
            border: 2px solid #e149a4;
            color: #e149a4;
            }
        
        .reiniciarbtn:active{
            
                border: 1px solid #d5007f;
                box-shadow: inset 0 0 6px 5px #b7006d;
                color:#d5007f;
                }

     
    

.ant-table-pagination.ant-pagination {
    display: flex !important;
    align-items:center !important;
    justify-content: center !important;
    text-align: center !important;
    float: none !important;
}

.ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid rgba(0, 0, 0, 0.02);
}

.tabla-verificaciones .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters::before {
    background-color: #494949 !important;
}

.tabla-verificaciones .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on, .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
    color: #db2491;
}

.tabla-verificaciones thead tr th {
    font-size: 12px;
    font-weight: 500;
    font-style: medium;
    font-stretch: medium;
    line-height: medium;
    letter-spacing: medium;
    color: #fff;
    background-color: #494949;
    border: none;
    vertical-align: middle;
    padding: 5px 10px;
    border-right: 1px solid rgba(0, 0, 0, 0.02);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
}

.tabla-verificaciones .ant-table-bordered .ant-table-thead > tr > th {
    border-right: 1px solid #494949 !important;
}

.ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #fff !important;
}
  
.tabla-verificaciones tbody tr {
    height: 2.188rem;
    border-bottom: 1px solid #494949 !important;
}
  
.tabla-verificaciones .ant-table .ant-table-tbody > tr:hover > td {
    background: unset;
}
  
.tabla-verificaciones tr:nth-child(even) {
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.5px;
    /*color: #000;
    border: none;*/
    vertical-align: middle;
    padding: 0.7rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    background-color: #efeded;
}
.tabla-verificaciones tr:nth-child(odd) {
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.5px;
    /*color: #000;
    border: none;*/
    vertical-align: middle;
    padding: 0.7rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    background-color: #fff;
}

.boton-modal-aceptar, .boton-modal-aceptar:focus {
    color: #fff;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #db2491;
  }

.boton-modal-aceptar:hover, .boton-modal-aceptar:disabled, .boton-modal-aceptar:disabled:hover {
    color: #fff;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #e149a4;
    border-radius: 5px;
    background-color: #e149a4;
}

.boton-modal-cancelar, .boton-modal-cancelar:focus {   
    color: #db2491;       
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #fff;
}

.boton-modal-cancelar:hover {    
    color: #db2491;   
    font-family: "Roboto";
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #db2491;
    border-radius: 5px;
    background-color: #fff;
}

.ant-modal-close-x {
    display: block;
    width: 56px;
    height: 56px;
    font-size: 16px;
    font-style: initial;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
}

.ant-modal-content {
    width: auto !important;
    height: auto !important;
}

.boton-agregar-verificacion, .boton-agregar-verificacion:focus {
    height: 75px;
    color: #333333;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: normal;
    font-size: 16px;
    border: 1px solid #d5007f;
    border-radius: 3px !important;
    background-color: #fff;
 }

.boton-agregar-verificacion:hover, .boton-agregar-verificacion:disabled, .boton-agregar-verificacion:disabled:hover {
    height: 75px;
    color: #333333;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: normal;
    font-size: 16px;
    border: 1px solid #d5007f;
    border-radius: 3px !important;
    background-color: #fff;
}

.icono-plus-verificacion{
    font-weight: bold;
    font-size: 16px;
}

.icono-form-verificacion{
    font-weight: bold;
    font-size: 24px;
    padding: 3px 0px;
}