.layout {
  //padding: 10px 24px 24px;
  //border-top: 1px solid rgb(0, 0, 0); //Dibuja una línea en la parte de arriba
  //margin-top: 20px; //Es el ancho del menú del proceso electoral
  background-color: #fff;
  .content {
    background: #fff;
    //padding: 24;
    //margin: 0;
    min-height: calc(100vh - 100px);
    padding: 25px 25px 25px 25px;
    border: 1px solid #999;
  }
}
.layout.ant-layout {
  background-color: #fff !important;
}
