body {
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

aside.ant-layout-sider-collapsed {
  background: transparent !important;
  flex: none !important;
  min-width: 0 !important;
  width: 0 !important;
}

aside.ant-layout-sider-collapsed ul.ant-menu {
  display: none;
}
aside:not(.ant-layout-sider-collapsed) .ant-layout-sider-trigger {
  margin-left: 205px;
}

.ant-layout-sider-trigger {
  border-radius: 50%;
  margin-top: 20px;
  width: 48px !important;
}

.ReactTable .-even {
  background-color: #efeded;
}

.ant-list-items li.ant-list-item:nth-child(even) {
  background-color: #efeded;
}